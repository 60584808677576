<template>
  <div id="update-log">
    <div id="panel-header">
      <span class="top-title">更新日志</span>
    </div>
    <Card class="card-ad">
      <div class="card-ad-detail">
        <h3>旨在做出简单好用的ERP管理系统</h3>
      </div>
    </Card>
    <div class="main-body">
      <div style="font-size: 22px;">
        <span style="margin:0 170px" :class="flag == 1 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(1)">网站</span>
        <span style="margin:0 160px" :class="flag == 2 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(2)">微信小程序</span>
        <Divider/>
      </div>
      <Timeline v-show="flag == 1">
        <TimelineItem>
          <p class="time">1.26.5</p>
          <div class="content">
            <code>2023-11-27</code>
            <ul class="ml-20">
              <li>销售支持仅退款操作</li>
              <li>优化销售采购状态位展示</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">1.26.4</p>
          <div class="content">
            <code>2023-11-19</code>
            <ul class="ml-20">
              <li>手动入库支持快捷入库</li>
              <li>领料时支持快捷出库</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">1.26.3</p>
          <div class="content">
            <code>2023-11-08</code>
            <ul class="ml-20">
              <li>支持快捷重置子账号密码</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">1.26.2</p>
          <div class="content">
            <code>2023-09-13</code>
            <ul class="ml-20">
              <li>修复系统已知bug</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">1.26.1</p>
          <div class="content">
            <code>2023-07-07</code>
            <ul class="ml-20">
              <li>首页新增库存预警提示</li>
              <li>单位信息模块添加开票信息</li>
              <li>新增批量下架功能</li>
              <li>货品列表新增导出功能</li>
              <li>修复已知bug</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem color="green">
          <p class="time">1.26.0</p>
          <div class="content">
            <code>2023-06-18</code>
            <ul class="ml-20">
              <li>新增素材库功能，方便客户添加素材图片</li>
              <li>采购支持运费填写，方便多场景用户使用采购模块</li>
              <li>销售模块支持销售合同记录</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem color="green">
          <p class="time">1.25.0</p>
          <div class="content">
            <code>2023-06-12</code>
            <ul class="ml-20">
              <li>生产管理模块支持生产计划功能</li>
              <li>优化仓库发货模块，支持记录物流费用流水</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">1.24.1</p>
          <div class="content">
            <code>2023-06-05</code>
            <ul class="ml-20">
              <li>客户信息支持分类功能</li>
              <li>优化领料模块，支持领料出库后，领料自动完成</li>
              <li>品类货品关联支持批量保存</li>
              <li>采购销售支持收付款完成功能</li>
              <li>采购销售支持收付款说明选项，默认显示定金、尾款、全款</li>
              <li>修复已知bug</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem color="green">
          <p class="time">1.24.0</p>
          <div class="content">
            <code>2023-05-29</code>
            <ul class="ml-20">
              <li>优化采购销售模块，支持多次付款(定金、尾款等操作)，更加贴近用户实际使用场景</li>
              <li>优化领料模块，支持领料多余货品入库</li>
              <li>优化采购模块，支持采购货品数据批量导入系统</li>
              <li>采购供应商支持分类功能</li>
              <li>项目信息支持和采购、销售关联，方便用户了解整个项目过程的支出和收入信息</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">1.23.1</p>
          <div class="content">
            <code>2023-05-21</code>
            <ul class="ml-20">
              <li>优化BOM单，支持按照货品品类批量创建货品BOM单</li>
              <li>优化检索框，支持按照BOM结构查询</li>
              <li>优化项目逻辑，项目支持绑定客户，销售支持关联所属项目</li>
              <li>优化采购流程，无需退货时可以便捷入库，减少操作流程</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem color="green">
          <p class="time">1.23.0</p>
          <div class="content">
            <code>2023-05-12</code>
            <ul class="ml-20">
              <li>系统优化，支持数量小数点功能，满足多行业需求</li>
              <li>修复已知bug</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">1.22.1</p>
          <div class="content">
            <code>2023-05-05</code>
            <ul class="ml-20">
              <li>优化销售单打印功能，模板支持定制化，多元化</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem color="green">
          <p class="time">1.22.0</p>
          <div class="content">
            <code>2023-04-07</code>
            <ul class="ml-20">
              <li>货品管理模块支持手动出入库操作</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem color="green">
          <p class="time">1.21.0</p>
          <div class="content">
            <code>2023-04-01</code>
            <ul class="ml-20">
              <li>新增栏目导航条功能，实现页面快捷跳转</li>
              <li>修复账号分配模块角色分配错误问题</li>
            </ul>
          </div>
        </TimelineItem>
        <div v-show="showMoreWebFlag">
        <TimelineItem color="green">
          <p class="time">1.20.0</p>
          <div class="content">
            <code>2022-08-21</code>
            <ul class="ml-20">
              <li>新增项目模块，采购支持项目功能</li>
              <li>优化采购模块，采购编号新增自定义编号</li>
              <li>优化采购模块，支持自定义显示列表栏目</li>
              <li>优化货品编号，不同类型货品有着不一样的编号前缀</li>
              <li>优化上架流程，支持批量上架操作</li>
              <li>优化货品条形码，支持条形码下载功能</li>
              <li>优化财务概览模块，支持月度年度收支同比功能</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem color="green">
          <p class="time">1.19.0</p>
          <div class="content">
            <code>2022-07-24</code>
            <ul class="ml-20">
              <li>货品新增条形码</li>
              <li>优化数据导出页面，格式化金钱展示方式</li>
              <li>修复BOM表成本计算数据异常问题</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">1.18.2</p>
          <div class="content">
            <code>2022-07-07</code>
            <ul class="ml-20">
              <li>优化登录页面，支持自定义登录框放置位置</li>
              <li>修复货品导入模块货品号导入异常问题</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">1.18.1</p>
          <div class="content">
            <code>2022-06-15</code>
            <ul class="ml-20">
              <li>优化系统前端框架，有效提高页面加载速度</li>
              <li>优化登录页面，提高背景图片加载速度</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem color="green">
          <p class="time">1.18.0</p>
          <div class="content">
            <code>2022-06-03</code>
            <ul class="ml-20">
              <li>新增货品标签设置，方便用户更好的归类货品</li>
              <li>优化登录页面，支持登录模块移动</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem color="green">
          <p class="time">1.17.0</p>
          <div class="content">
            <code>2022-05-12</code>
            <ul class="ml-20">
              <li>新增仓库概况页面</li>
              <li>优化权限功能底层实现</li>
              <li>优化登录页面，背景图片自动获取，图片来源必应</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">1.16.2</p>
          <div class="content">
            <code>2022-04-19</code>
            <ul class="ml-20">
              <li>优化页面页码展示功能，支持历史页面选择记录功能</li>
              <li>优化登录过期检测机制</li>
              <li>优化货品选择组件搜索框，使搜索更加人性化</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">1.16.1</p>
          <div class="content">
            <code>2022-03-30</code>
            <ul class="ml-20">
              <li>新增货品列表栏目自定义配置功能</li>
              <li>优化各列表查询模块，使其更利于查询操作</li>
              <li>优化系统基础设施，提升系统健壮性</li>
              <li>优化登录页面，适配大屏显示器</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem color="green">
          <p class="time">1.16.0</p>
          <div class="content">
            <code>2022-03-04</code>
            <ul class="ml-20">
              <li>新增部门功能</li>
              <li>优化账号模块，账号支持绑定部门</li>
              <li>优化销售模块，支持销售部门和销售人员绑定</li>
              <li>优化货品模块，货品列表支持选择每页显示多少数据功能</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem color="green">
          <p class="time">1.15.0</p>
          <div class="content">
            <code>2022-02-18</code>
            <ul class="ml-20">
              <li>货品模块新增导入功能，支持货品批量导入</li>
              <li>优化货品类型，新增虚拟产品类型</li>
              <li>优化客户展示顺序，将售额高的用户展示在前面，方便用户选择</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">1.14.2</p>
          <div class="content">
            <code>2022-01-29</code>
            <ul class="ml-20">
              <li>优化配置环境性能</li>
              <li>优化登录模块，支持多端同时登录</li>
              <li>支持用户头像，用户头像目前来源微信小程序</li>
              <li>修复网站账号长时间不退出问题</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">1.14.1</p>
          <div class="content">
            <code>2022-01-19</code>
            <ul class="ml-20">
              <li>优化常规代码</li>
              <li>修复上传凭证归类混乱问题</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem color="green">
          <p class="time">1.14.0</p>
          <div class="content">
            <code>2021-12-24</code>
            <ul class="ml-20">
              <li>新增领料货品库存情况</li>
              <li>新增简易生产功能,实现简易生产能耗记录</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem color="green">
          <p class="time">1.13.0</p>
          <div class="content">
            <code>2021-11-01</code>
            <ul class="ml-20">
              <li>新增帮助中心使用文档功能，用户可通过改文档快速了解系统</li>
              <li>新增货品品类功能，方便用户更好的归类货品</li>
              <li>修复货品选择组件中选中货品默认选择的问题</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">1.12.3</p>
          <div class="content">
            <code>2021-10-20</code>
            <ul class="ml-20">
              <li>新增帮助中心模块</li>
              <li>优化采购模块，支持成品采购</li>
              <li>优化常规系统代码</li>
              <li>优化采购退货、销售退货数据展示，方便客户更加直观了解退货金额情况</li>
              <li>优化数据加载延迟显示样式</li>
              <li>修复盘点执行后，库存变动未记录问题</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">1.12.2</p>
          <div class="content">
            <code>2021-10-09</code>
            <ul class="ml-20">
              <li>新增导出日志页面</li>
              <li>优化销售导出，支持自定义导出标题</li>
              <li>优化采购销售运费流程</li>
              <li>优化'取消'按钮，名称改为'关闭'，防止用户理解歧义</li>
              <li>优化采购退货流程，支持部分退货</li>
              <li>优化销售退货流程，支持部分退货</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">1.12.1</p>
          <div class="content">
            <code>2021-09-21</code>
            <ul class="ml-20">
              <li>新增货品销量功能，方便用户知晓货品销量情况</li>
              <li>优化登录按钮，防止并发处理</li>
              <li>优化个人中心页面，支持密码更改</li>
              <li>优化采购、销售列表分页，记录用户喜欢</li>
              <li>页面常规优化及性能优化</li>
            </ul>
          </div>
        </TimelineItem>
          <TimelineItem color="green">
          <p class="time">1.12.0</p>
          <div class="content">
            <code>2021-09-12</code>
            <ul class="ml-20">
              <li>新增账户总额功能</li>
              <li>新增账户数量限制功能</li>
              <li>优化登录页面，增加宣传图片</li>
              <li>优化销售退货逻辑</li>
              <li>优化销售出库单流程</li>
              <li>修复销售退货异常场景</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem color="green">
          <p class="time">1.11.0</p>
          <div class="content">
            <code>2021-08-25</code>
            <ul class="ml-20">
              <li>新增调拨入库功能</li>
              <li>新增库存变动记录功能</li>
              <li>优化销售单打印功能</li>
              <li>优化在线时长功能</li>
              <li>优化往来单位，取消手机号必填限制</li>
              <li>优化入库单，支持调拨入库</li>
              <li>优化出库单，执行调拨出库</li>
              <li>修复货品关联文件上传失败问题</li>
              <li>修复已知bug</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem color="green">
          <p class="time">1.10.0</p>
          <div class="content">
            <code>2021-07-10</code>
            <ul class="ml-20">
              <li>新增发货单功能</li>
              <li>优化图片预览，组件化同时支持多图切换查看</li>
              <li>优化单号拷贝方式，方便用户拷贝必要数据</li>
              <li>优化货品列表货品图片上传功能</li>
              <li>修复图片上传组件相同图片重复上传问题</li>
              <li>修复修改采购单时，凭证无法保存问题</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem color="green">
          <p class="time">1.9.0</p>
          <div class="content">
            <code>2021-06-28</code>
            <ul class="ml-20">
              <li>新增销售模块售价配置功能</li>
              <li>个人中心新增更新日志，便于用户了解系统更新迭代情况</li>
              <li>采购和销售新增优惠字段，方便记录优惠情况</li>
              <li>优化采购订单模块凭证上传功能，更好地方便用户上传采购凭证</li>
              <li>优化页面布局，方便用户更好使用</li>
              <li>优化销售模块，去除快递单号、物流及运费</li>
              <li>修复已知的系统bug</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem color="green">
          <p class="time">1.8.0</p>
          <div class="content">
            <code>2021-05-12</code>
            <ul class="ml-20">
              <li>新增权限模块1.0，满足用户基础权限需求</li>
              <li>新增enter按键登录功能，方便用户快捷登录</li>
              <li>优化流水单号，增强单号可读性</li>
              <li>优化采购销售单号，增强单号可读性</li>
              <li>优化杂项结算，汇总金额默认为有效流水单的汇总金额</li>
              <li>优化基础结构，提升代码可读性</li>
              <li>优化货品选择组件，支持行点击选择数据</li>
              <li>优化操作日志列表，数据最长展示最近的1000条数据，其他数据通过筛选查找</li>
              <li>修复基础框架升级部分模块不兼容问题</li>
            </ul>
          </div>
        </TimelineItem>
          <TimelineItem>
            <p class="time">1.7.7</p>
            <div class="content">
              <code>2021-04-16</code>
              <ul class="ml-20">
                <li>修复销售模块客户数量只显示二十个的问题</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem>
            <p class="time">1.7.6</p>
            <div class="content">
              <code>2021-03-08</code>
              <ul class="ml-20">
                <li>优化导出模块凭证，支持多图展示</li>
                <li>修复销售模块凭证不上传问题</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem>
            <p class="time">1.7.5</p>
            <div class="content">
              <code>2021-02-27</code>
              <ul class="ml-20">
                <li>优化销售模块凭证，支持多图凭证</li>
                <li>修复采购单采购日期不填写无法创建采购单问题</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem>
            <p class="time">1.7.4</p>
            <div class="content">
              <code>2021-01-19</code>
              <ul class="ml-20">
                <li>新增采购模块备注功能</li>
                <li>优化采购订单添加功能界面</li>
                <li>优化采购模块，采购时间自主选择</li>
                <li>修复已取消销售订单，无法看明细问题</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem>
            <p class="time">1.7.3</p>
            <div class="content">
              <code>2021-01-12</code>
              <ul class="ml-20">
                <li>销售单详情支持凭证大图查看</li>
                <li>财务流水支持凭证大图查看</li>
                <li>财务流水支持凭证大图查看</li>
                <li>导出查询日期支持倒序</li>
                <li>数据导出支持链接生成</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem>
            <p class="time">1.7.2</p>
            <div class="content">
              <code>2021-01-03</code>
              <ul class="ml-20">
                <li>销售列表支持多选</li>
                <li>杂项结算支持类别多选</li>
                <li>优化财务概览杂项概览部分数据展示于物流概览的问题</li>
                <li>修复财务概览有效的数据删除后叠加的问题</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem>
            <p class="time">1.7.1</p>
            <div class="content">
              <code>2021-01-02</code>
              <ul class="ml-20">
                <li>销售查询支持时间降序显示</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem color="green">
            <p class="time">1.7.0</p>
            <div class="content">
              <code>2020-12-29</code>
              <ul class="ml-20">
                <li>新增销售模块导出服务</li>
                <li>出库模块，支持下架默认库位</li>
                <li>个人中心栏目增加logo</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem>
            <p class="time">1.6.1</p>
            <div class="content">
              <code>2020-11-02</code>
              <ul class="ml-20">
                <li>优化销售模块，支持填写销售日期，默认为当前时间</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem color="green">
            <p class="time">1.6.0</p>
            <div class="content">
              <code>2020-10-21</code>
              <ul class="ml-20">
                <li>新增领料模块</li>
                <li>杂项流水支持收入类型搜索</li>
                <li>优化结算模块，支持订单号搜索和时间多种选择</li>
                <li>优化导航栏</li>
                <li>优化出库单，支持多种类型出库</li>
                <li>优化财务预览，显示总计</li>
                <li>修复分页bug</li>
                <li>修复不同账号操作出库生成出库单号重复问题</li>
                <li>修复盘点货品数据为空时，可以盘点完成操作问题</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem>
            <p class="time">1.5.6</p>
            <div class="content">
              <code>2020-09-29</code>
              <ul class="ml-20">
                <li>新增销售模块客户筛选功能</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem>
            <p class="time">1.5.5</p>
            <div class="content">
              <code>2020-09-21</code>
              <ul class="ml-20">
                <li>新增销售模块备注字段</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem>
            <p class="time">1.5.4</p>
            <div class="content">
              <code>2020-08-30</code>
              <ul class="ml-20">
                <li>新增货品模块上传文件功能</li>
                <li>优化货品模块</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem>
            <p class="time">1.5.3</p>
            <div class="content">
              <code>2020-08-24</code>
              <ul class="ml-20">
                <li>修复销售单修改，货品详情不生效问题</li>
                <li>修复采购单修改，货品详情不生效问题</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem>
            <p class="time">1.5.2</p>
            <div class="content">
              <code>2020-08-13</code>
              <ul class="ml-20">
                <li>优化状态表示语</li>
                <li>优化盘点详情页，显示操作员</li>
                <li>修复采购单创建、销售单创建时凭证显示bug</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem>
            <p class="time">1.5.1</p>
            <div class="content">
              <code>2020-08-05</code>
              <ul class="ml-20">
                <li>优化出库下架操作</li>
                <li>修复杂项结算创建超时问题</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem color="green">
            <p class="time">1.5.0</p>
            <div class="content">
              <code>2020-08-02</code>
              <ul class="ml-20">
                <li>新增采购凭证功能</li>
                <li>新增销售凭证功能</li>
                <li>优化凭证图片查看大小</li>
                <li>修复货品组件选中货品取消时，选中数量不正确问题</li>
                <li>修复销售模块金额计算溢出问题</li>
                <li>修复出库下架时下架按钮只能点击一次的问题</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem color="green">
            <p class="time">1.4.0</p>
            <div class="content">
              <code>2020-07-29</code>
              <ul class="ml-20">
                <li>新增杂项结算流水凭证功能</li>
                <li>优化盘点模块,待盘点仓库库区明细支持显示隐藏</li>
                <li>优化杂项结算，支持显示总支出和总收入</li>
                <li>优化设置模块导航栏</li>
                <li>优化杂项结算搜索模块</li>
                <li>优化货品组件支持分页选取货品，支持选中货品去除功能</li>
                <li>修复BOM结构创建bug</li>
                <li>修复删除盘点中库存仍提示有库区在盘点中问题</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem color="green">
            <p class="time">1.3.0</p>
            <div class="content">
              <code>2020-07-28</code>
              <ul class="ml-20">
                <li>新增bom结构复制创建功能，加快物料bom结构的创建</li>
                <li>优化货品组件，支持分页选取货品</li>
                <li>修复上传图片bug</li>
                <li>修复盘点模块细节bug</li>
                <li>修复货品模块细节bug</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem>
            <p class="time">1.2.3</p>
            <div class="content">
              <code>2020-07-14</code>
              <ul class="ml-20">
                <li>新增货品编号自定义功能</li>
                <li>优化杂项结算功能</li>
                <li>修复已知bug</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem>
            <p class="time">1.2.2</p>
            <div class="content">
              <code>2020-07-10</code>
              <ul class="ml-20">
                <li>优化盘点模块</li>
                <li>优化货品添加模块，货品添加模块组件化</li>
                <li>修复货品添加组件重置失效bug</li>
                <li>修复已知bug</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem>
            <p class="time">1.2.1</p>
            <div class="content">
              <code>2020-07-10</code>
              <ul class="ml-20">
                <li>新增类别</li>
                <li>优化杂项结算</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem color="green">
            <p class="time">1.2.0</p>
            <div class="content">
              <code>2020-07-07</code>
              <ul class="ml-20">
                <li>新增bom表类标搜索</li>
                <li>新增bom表生效失效功能</li>
                <li>优化bom结构总价显示</li>
                <li>优化盘点限制全盘解除，支持库区部分盘点，未盘点的库存不动</li>
                <li>优化盘点功能，功能更加人性化</li>
                <li>修复仓库缓存bug</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem color="green">
            <p class="time">1.1.0</p>
            <div class="content">
              <code>2020-07-05</code>
              <ul class="ml-20">
                <li>bom结构支持半成品添加</li>
                <li>bom结构支持清算成本</li>
                <li>bom创建时支持bom结构检测</li>
                <li>修复已发现的bug</li>
              </ul>
            </div>
          </TimelineItem>
          <TimelineItem color="green">
            <Icon type="ios-trophy" slot="dot"></Icon>
            <p class="time">1.0.0</p>
            <div class="content">
              <code>2020-07-05</code>
              <ul class="ml-20">
                <li>系统基础版功能上线</li>
              </ul>
            </div>
          </TimelineItem>
        </div>

        <Button v-show="!showMoreWebFlag" type="text" class="main-font-color" @click="showMoreWeb" >查看更多</Button>


      </Timeline>

      <Timeline v-show="flag == 2">
        <TimelineItem>
          <p class="time">0.5.0</p>
          <div class="content">
            <code>2022-08-29</code>
            <ul class="ml-20">
              <li>框架基础组件升级</li>
              <li>新增货品条形码扫码功能，扫条形码查看货物详细信息</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">0.4.3</p>
          <div class="content">
            <code>2022-07-02</code>
            <ul class="ml-20">
              <li>框架基础组件升级</li>
              <li>修复组件设计后的不兼容问题</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">0.4.2</p>
          <div class="content">
            <code>2022-06-17</code>
            <ul class="ml-20">
              <li>优化销售模块列表、及详情页面</li>
              <li>优化财务模块及详情页面</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">0.4.1</p>
          <div class="content">
            <code>2022-05-10</code>
            <ul class="ml-20">
              <li>优化登录模块</li>
              <li>优化查看页面，提升页面可读性</li>
              <li>优化列表页面，提升页面可读性</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">0.4.0</p>
          <div class="content">
            <code>2022-03-17</code>
            <ul class="ml-20">
              <li>优化首页模块，提升模块使用的便捷性</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">0.3.0</p>
          <div class="content">
            <code>2022-02-18</code>
            <ul class="ml-20">
              <li>新增货品模块，支持货品列表和货品查看功能</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">0.2.0</p>
          <div class="content">
            <code>2022-01-29</code>
            <ul class="ml-20">
              <li>首页支持用户头像，同步头像至系统</li>
            </ul>
          </div>
        </TimelineItem>
        <TimelineItem>
          <p class="time">0.1.0</p>
          <div class="content">
            <code>2022-01-20</code>
            <ul class="ml-20">
              <li>简易基础版上线</li>
            </ul>
          </div>
        </TimelineItem>
      </Timeline>

    </div>
    <BackTop></BackTop>
  </div>
</template>

<script>
import '@/css/common.css';

export default {
  name: "UpdateLog",
  data() {
    return {
      flag: 1,
      showMoreWebFlag: false,
    }
  },
  methods: {
    selectTap(flag) {
      this.flag = flag;
    },
    showMoreWeb() {
      this.showMoreWebFlag = true;
    }
  }
}
</script>

<style scoped>
#update-log {
  width: 960px;
  padding: 23px 40px;
  min-height: calc(100vh - 50px);
  margin: 2px auto;
}

#panel-header {
  position: relative;
  border: none;
}

.main-body {
  margin-top: 30px;
  width: 960px;
}

.card-ad {
  width: 960px;
  height: 260px;
  background-color: #2775ff;
  margin-top: 15px;
}

.card-ad-detail {
  text-align: center;
  color: #fff;
  font-size: 40px;
  line-height: 220px;
  height: 260px;
}

.time {
  font-size: 20px;
  font-weight: 400
}

.content {
  padding-left: 10px;
  color: #515a6e;
  font-size: 14px;
  line-height: 26px;
}

.select-flag {
  font-weight: 500;
  font-size: 24px;
}
</style>
<style>
.main-body .ivu-timeline .ivu-timeline-item-content {
  top: -8px;
}
</style>
